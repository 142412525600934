@import 'fonts.scss';
@import 'colours.scss';
@import 'footer.scss';
@import 'media.scss';

@import 'loading.scss';

h1{
  font-family: SUSTAIN-Regular,sans-serif;
  font-size: 26px;
  color: $orange;
}
h2{
  font-family: SUSTAIN-Regular,sans-serif;
  font-size: 22px;
  color: $orange;
  margin: 10px 0px;
}
h3{
  font-family: 'plantin',sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 1.22;
  color: $brown;
}
h4{
  font-family: plantin-condensed,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: $brown;
}
body, label, label.checkbox_container{
  font-family: plantin,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.button-text{
  font-family: SUSTAIN-Regular,sans-serif;
  font-size: 18px;
  color: $cream;
}
.footer-text{
  font-family: plantin,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin: 15px 0px;
}
.footer-copyright{
  font-family: plantin,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}
.footer-poweredby{
  font-family: avenir,sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: #ffffff;
}
.heading{
  text-align: center;
}

header{
  text-align: center;
  #backLink{
    position: absolute;
    left: 25px;
    top: 60px;
    height: 50px;
    width: 50px;
    background-image: url('../../assets/back.png');
    background-repeat: no-repeat;
  }
  img{
    margin: 30px 0px;
  }
}

.button{
  background-color: $blue;
  width:80%;
  max-width: 235px;
  border: none;
  &:hover, &:active, &:focus, &:visited{
    background-color: $blue;
    border:none;
  }
}

#homePage{
  text-align: center;
  margin: 100px auto;
  img{
    margin: 40px auto;
  }
  #secretButton{
    position: absolute;
    background-color: $cream;
    top:0;
    right:0;
    width: 100px;
    height: 100px;
  }
}
