@import 'core.scss';
@import 'colours.scss';


.contactPage{
  #footerWithForm{
    display:none;
  }
  .button{
    padding: 6px 12px;
  }
  // .disabled{
  //   background-color: $grey;
  // }
}
select{
  height: 40px;
  width: 90px!important;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #e9edf0;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.contactHeading{
  text-align: center;
  padding-bottom: 30px;
  border-bottom: $grey 1px solid;
}

#contactFormPage {
  margin-bottom: 150px;
  h2{
    margin: 40px auto 20px auto;
  }
  #formButton{
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
  }
}

/* CHECK BOX */
/* The container */
.checkbox_container {
display: block;
position: relative;
padding-left: 35px;
padding-top: 0px;
margin: 20px 0px;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
a{
  color: $brown;
  text-decoration: underline;
}
}

/* Hide the browser's default checkbox */
.checkbox_container input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: #e9edf0;
border: 1px solid #1a1823;
border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
background-color: #e9edf0;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
background-color: $blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
left: 7px;
top: 0px;
width: 10px;
height: 18px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}
