@import 'colours.scss';
@import 'media.scss';
@import 'core.scss';

.metal{
  .treatyourself{
    margin-top: 25px;
  }
  .products{
    border-top: 1px #dddddd solid;
    padding-top: 60px;
    margin-top: 60px;
    h2{
      margin-bottom: 15px;
    }
    .product_item{
      margin-bottom: 40px;
      @media screen and (max-width: $iphone5) {
        img{
          width: 100%;
        }
      }

      .product_info{
        margin: 10px auto;
        h4{
          margin: 0 auto;
        }
      }
    }
  }
}

.modal{
  .modal-content{
    background-color: $cream;
    .modal-body{
      div, img{
        display:inline-block;
        width:50%;
      }
      div {
        // width:50%;
        padding-left: 15px;
        vertical-align: middle;
      }
    }
    .modal-footer{
      padding: 0px;
      a{
        width:50%;
        text-align: center;
        padding: 10px;
        &:last-child{
          border-left:1px solid $grey;
        }
      }
    }
  }
}

.metal-thanks{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30%;
  h1, h3{
    margin: 30px;
  }
}
