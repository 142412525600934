@import 'colours.scss';
@import 'media.scss';
@import 'core.scss';

.glass{
    .contentWrapper{
      // overflow: hidden;
      // background-image: url('../../assets/glass/line.svg');
      // background-repeat: no-repeat;
      // background-position-x: 25px;
      // background-position-y: 80px;
      margin-top: 40px;
    }
    .timelineContentItem{
      display: flex;
    }
    .timelinePoint{
      width:25px;
      height:25px;
      background-color: $orange;
      border-radius: 50%;
      margin: 25px;
      display:block;
      &.noImage{
        margin: 15px 25px;
      }
    }
    .GlassContentType{
      background: #ffffff;
      border: 1px solid $dark-cream;
      // width:80%;
      width: 100%;
      margin-bottom: 40px;

      img{
        width: 100%;
        padding: 10px;
      }
      .containerText{
        padding: 10px;
        h4{
          color: $brown;
          margin: 10px auto;
          span{
            color: $black;
            padding-left: 15px;
          }
        }
      }
    }
    .milkGlass .ImageContainer img{
      height: 120px;
    }
    .MapContainer img{
      padding: 0px;
    }
}

#timelineLine{
  display:block;
  position:absolute;
  margin-top: 30px;
  margin-left: 36px;
  border-left:2px solid $orange;
  height:0%;
  width: 10px;
  text-align:center;
  z-index: -10;
  animation: line 5s linear forwards;
  animation-delay: 1.5s;
}

@keyframes line {
  from {
    // top:0%;
    // height:0vh;
    height: 0px;
  }
  to {
    // top:50%;
    // height:309vh;
    height: 2050px;
  }
}
