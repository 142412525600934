@import 'colours.scss';
@import 'core.scss';

.scanMe{
  text-align: center;
  display: block;

  img, h1, .button, span{
    margin: 0px auto;
    display: block;
    position:absolute;
    left:0;
    right: 0;
  }
  .logo{
    top: 197px;
  }
  #shirt{
    top: 320px;
    width: 160px;
  }
  #ray_1, #ray_2{
    display:none;
  }
  #ray_1{
    top: 275px;
  }
  #ray_2{
    top: 235px;
  }
  h1{
    top: 599px;
  }
  .button{
    top: 669px;
  }
}


.scanMeAnimate{
  #shirt, .button{
    // visibility: hidden;
    opacity: 1;
    animation: fadeOut 0.5s linear forwards;
  }
  h1{
    animation: slideUp 1s ease-out forwards;
    animation-delay: 0.75s;
  }
  .logo{
    animation: slideDownEnlarge 1s ease-out forwards;
    animation-delay: 0.75s;
  }
  #ray_1, #ray_2{
    display:block;
    opacity: 0;
  }
  #ray_1{
    background: url('../../assets/plastic/ray_1.svg') no-repeat;
    background-position: center;
    width: 200px;
    height: 60px;
    animation: rays 2s linear forwards;
    animation-iteration-count: infinite;
    animation-delay: 1.5s;
  }
  #ray_2{
    background: url('../../assets/plastic/ray_2.svg') no-repeat;
    background-position: center;
    width: 255px;
    height: 90px;
    animation: rays 2s linear forwards;
    animation-iteration-count: infinite;
    animation-delay: 1.75s;
  }
}

@keyframes rays {
    0% {opacity: 0;}
    20% {opacity: 1;}
    70% {opacity: 1;}
    80% {opacity: 0;}
    100% {opacity: 0;}
 }

@keyframes slideDownEnlarge {
    0% {width: 96px;}
    100% {top: 319px; width: 144px;}
 }

@keyframes slideUp {
    0% {top: 599px;}
    100% {top: 519px;}
 }

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0; display:none;}
 }


.plasticData{
  margin: 20px auto 0px auto;
  #backButton{
    position: absolute;
    top: 80px;
    width: 20px;
    height: 20px;
    left: 50px;
    background-image: url('../../assets/return.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.dataPage{
  text-align: center;
  display: block;

  .dataPage-heading{
    color: $brown;
    font-size: 40px;
    margin: 20px auto;
  }
  .dataPage-image{
    margin: 40px auto;
    width:200px;
  }
  .dataPage-productHeading{

  }
  h2{
    color: $blue;
  }
  .materialData{
    h1{
      color: $orange;
    }
  }

  .dataGroups{
    .dataGroup{
      padding-top: 300px;
      background-repeat: no-repeat;
      background-position: top center;
    }
    .data{
      margin-bottom: 20px;
    }
    .productDetails{
      background-image: url('../../assets/plastic/data-group_topline.svg');
    }
    .materialData{
      background-image: url('../../assets/plastic/data-group_line.svg');
    }
    .manufactureData{
      background-image: url('../../assets/plastic/data-group_line-pin.svg');
      padding-top: 390px;
    }
    .distanceData{
      background-image: url('../../assets/plastic/data-group_line.svg');
    }
    .distributorData{
      background-image: url('../../assets/plastic/data-group_line-truck.svg');
      padding-top: 390px;
    }
    .storeData{
      background-image: url('../../assets/plastic/data-group_line-bottom.svg');
      padding-top: 390px;
    }
  }
}
