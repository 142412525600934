@import 'colours.scss';

footer{
  background-color: $dark-cream;
  text-align: center;
  padding: 30px 30px 60px 30px;
  margin-top: 50px;
  clear: both;
  #footerForm{
    margin: 15px auto 40px;
    input{
      max-width: 400px;
      margin:0 auto;
    }
  }
  .button{
    background-color: $blue;
    width:80%;
    border: none;
  }
  .footer-text{
    color: $black;
  }
  .footer-copyright{
    margin: 20px auto;
    max-width: 450px;
    a{
      color: $black;
      text-decoration: underline;
    }
  }
  .poweredbySE{
    background-color: $black;
    width: 80%;
    max-width: 250px;
    border-radius: 40px;
    margin: 20px auto 0px auto;
    display: block;
    img, .footer-text{
      display: inline-block;
      vertical-align: middle;
    }
    img{
      min-width: 120px;
      width: 40%;
      padding: 10px;
    }
  }
}
