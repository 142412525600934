.loading{
  text-align: center;
  margin-top:30%;

  h2{
    font-size: 26px;
    display: block;
  }
  h3{
    font-size: 16px;
    display: block;
  }
}

.loading-circle{
  background-image: url('../../assets/target-red-circle.svg');
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 0px;
}
#vivus{
  display: block;
  height: 108px;
  text-align: center;
}
