@import 'core.scss';

.paper{
  .stamps{
    text-align: center;
    .row{
      margin: 30px -15px;
    }
  }
  .stamp{
    img{
      width:100%;
    }

  }
  .stamp-new{
    background-image: url('../../assets/paper/stamp-circle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height:100%;
    img{
      width: 100%;
      vertical-align: middle;
    }
  }
  // .stamp:after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 35%;
  // }
  .impact{
    text-align: center;
    margin:110px auto 80px auto;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 20px;
      margin: -40px auto 0 auto;
      height: 20px;
      background-image: url('../../assets/paper/impact-arrow.svg');
      background-repeat: no-repeat;
    }
    h1{
      color: #97C2C0;
    }
    .impact-cupsUsedToday{
      padding: 80px 0px 0px 0px;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 30px;
        margin: -50px auto 0 auto;
        height: 40px;
        background-image: url('../../assets/paper/impact-cupsUsedToday.svg');
        background-repeat: no-repeat;
      }
    }
    .impact-recycledToday{
      padding: 80px 0px 20px 0px;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 40px;
        margin: -50px auto 0 auto;
        height: 40px;
        background-image: url('../../assets/paper/impact-recycledToday.svg');
        background-repeat: no-repeat;
      }
    }
  }
}
